import React from "react"
import Layout from './../components/Layouts';
import Loadable from 'react-loadable';

import { Avatar, Divider, Collapse, Icon, Row, Col } from 'antd'
// import Img  from 'gatsby-image';
import PrinterIcon from "./3d-printer-svgrepo-com.svg"
import CodeIcon from "./code-programming-signs-outlines-svgrepo-com.svg"
import CameraIcon from "./photo-camera-svgrepo-com.svg"
import RobotIcon from "./robot-svgrepo-com.svg"
import KatexEquation from '../common/katex-equation'
import './Index.css'
import CloudStack from "./cloud-dev-stack-shaded.svg"
import me from './me20.jpg'
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();

// const isBrowser = typeof window !== "undefined"


// if (isBrowser) {
//   import CodersRankSkillsChart from '@codersrank/skills-chart';
//   window.customElements.define('codersrank-skills-chart', CodersRankSkillsChart);
// }
const LoadableCodersRankWrapper = Loadable({
  loader: () => import('./CodersRankWrapper'),
  loading() {
    return <div>Loading...</div>
  },
})


const { Panel } = Collapse;

export const frontmatter = {
  layoutType: `page`,
  path: `/about/`,
}

const customPanelStyle = {
  // background: '#f7f7f7',
  fontFamily: "Charter,Georgia,serif",
  background: 'white',
  borderRadius: 4,
  marginBottom: 24,  
  border: 0,
  letterSpacing: 1,
  overflow: 'hidden',
  lineHeight: 1.45,
  fontSize: "1.3em",  
  // fontSize: "16px",
  // fontWeight: "600"
};

const bodyStyle = {
  fontFamily: "Charter,Georgia,serif",
  fontSize: "1em",  
  lineHeight: 1.45
}

const panelBody = {
  fontFamily: "Charter,Georgia,serif",
  fontSize: "1em",  
  paddingLeft: "8px",
  lineHeight: "30px", 
  marginTop: 10
}

class About extends React.Component {
  
  render() {
    // const profilePic = this.props.data.profilePic.childImageSharp;
    // console.log(this.props.data.profilePic);
    return (
      <Layout data={this.props.data} location={this.props.location}>
      <div className="container" style={{maxWidth: 800, marginTop: 24}}>
          <h2 className="about-header-title">
            <Avatar src={me} />
            &nbsp; ASH KATNORIA
            <Divider type="vertical" /> 
            <a href="https://github.com/katnoria" target="_blank" rel="noopener noreferrer">GITHUB</a>
            <Divider type="vertical" /> 
            <a href="https://500px.com/katnoria" target="_blank" rel="noopener noreferrer">PHOTOGRAPHY</a>
            <Divider type="vertical" /> 
            <a href="https://www.instagram.com/holy_ash/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
            <Divider type="vertical" /> 
            {/* <a href="#" target="_blank" rel="noopener noreferrer">CONTACT</a> */}
          </h2> 
          <div>
            <p style={{fontSize: '0.31415em', marginTop: 8}}>
              EPISODE: 4, LR: 1e-5, EPS: 0.5
              </p>
          </div>
          <div>
            <p style={{color: "gray", fontSize: '0.8em',}}>
              <span>AI </span>
              <Divider type="vertical" />
              <span>BI </span>
              <Divider type="vertical" />
              <span>CI </span>
              <Divider type="vertical" />
              <span>DI </span>
              <Divider type="vertical" /> 
              <span>😀 </span>               
              <Divider type="vertical" /> 
              <span>🤣 </span>                            
            </p>
          </div>
          <div style={{lineHeight: "24px", marginTop: 10}}>
            <p style={{lineHeight: "30px"}}>
               <KatexEquation equation="\text{Machine Learning}_\text{play} \cup \text {Full Stack Software Engineering}_\text{work}" />
            </p>
          </div>
          <div style={{lineHeight: "24px", marginTop: "16px"}}>
            <div className="bodyStyle">
        <Collapse 
            style={{backgroundColor: 'white', paddingBottom: 24}}
            bordered={true}
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
            defaultActiveKey={['1', '5', '7']}
        >
          <Panel header="PAST" key="1" style={customPanelStyle}> 
                <p className="panelBody">
                  I have over 20 years of Experience in <b>Building</b> and <b>Shipping</b> Technology Solutions.
                </p>
                <p className="panelBody" style={{marginTop: "20px"}}>
                  For the most part, I worked as Software Engineer at ABN AMRO and The Royal Bank of Scotland. 
                  During my time there, I worked on building and maintaining Pricing and Trade Capture systems for Derivatives Trading business; 
                  Automated middle and back-office processes for Fixed Income and Prime Brokerage business in 
                  Tokyo and, helped establish ION-based Electronic trading platform for Fixed Income E-Commerce business in Singapore and Tokyo.

                </p>
          </Panel>
          <Panel header="PRESENT" key="2" style={customPanelStyle}>          
              <p className="panelBody">
                I started my work life at a Startup in 2000 and am now back into the startup world , a full circle.
                We are building a no-code suite of data solutions that will help small and medium enterprises make better use of their data.                
              </p>
              <p style={{marginTop:"20px"}}>
                My interests include learning and playing with <b>Software 2.0</b> stack.
              </p>
              <p style={{marginTop:"10px"}}>
                I find representation learning and reinforcement learning exciting and fun to work with. 
                Hence, my posts here mainly focus on Deep Learning in Computer Vision, Natural Language Processing, 
                and Reinforcement Learning.
              </p>
          </Panel>
          <Panel header="FUTURE" key="3" style={customPanelStyle}>          
            <p>
               Developing ..... 😉
            </p>
          </Panel>

          <Panel header="INTERESTS" key="4" style={customPanelStyle}>          
            <div>
            <p style={{lineHeight: "40px", marginBottom: "10px"}}>I love to build 😀</p>
            <a href="https://github.com/katnoria">
              <img src={CodeIcon} width="64"  style={{backgroundColor: "#E1451E"}} /></a>
            <Divider type="vertical" /> 
            <a href="/roboto">
              <img src={RobotIcon} width="64" style={{backgroundColor: "#59E11E"}} /></a>
            <Divider type="vertical" /> 
              <img src={PrinterIcon} width="64" style={{backgroundColor: "#1EBAE1"}} />
            <Divider type="vertical" /> 
            <a href="https://500px.com/katnoria">
              <img src={CameraIcon} width="64" style={{backgroundColor: "#A61EE1", opacity: .8}} /></a>
            </div>

          </Panel>
          <Panel header="LANGUAGES" key="5" style={customPanelStyle}>          
            <div style={{marginBottom: "10px"}}>
              <span className="panelBody">
                <a href="https://profile.codersrank.io/">CodersRank</a> powered snapshot based on my <strong>personal</strong> GitHub repositories.
              </span>
              <LoadableCodersRankWrapper />
              {/* {
                isBrowser &&  <codersrank-skills-chart username="katnoria" labels
                skills="R, Rust, Jupyter Notebook, Rust, Swift, Objective-C, Javascript, Go, Python"
                legend
                tooltip
                branding={false}
                />

              } */}
            </div>
            <div>
              <p>
                Previously I used Sourcerer, but the app stopped working sometime last year and looks like it is no longer maintained. 
                Note the emphasis on <u>"personal repositories"</u>, you really don't want external tools to scan your work repositories.
              </p>     
              {/* <p style={{marginTop: "10px"}}>
                <b>Q: Why only use personal repositories?</b>
              </p> 
              <p>
                A: Would you really want to run an external tool on your work repo or projects that you do for your client? 🧐 🧐 
              </p>  */}
            {/* <span className="panelBody">
                The breakdown of programming languages that use in my projects, outside my day job.
                This plot is created by sourcerer.io by scanning my public and private github repositories.                 
              </span> */}

              {/* <img src="https://s3.amazonaws.com/katnoria.com/latest-code-pie.png" width="200"/> */}
            </div>
          </Panel>
          {/* <Panel header="SOFTWARE STACK" key="6" style={customPanelStyle}>
            <div>
              <p style={{lineHeight: "40px"}}>DEEP LEARNING: Keras, Pytorch, Tensorflow</p>
              <p style={{lineHeight: "40px"}}>PYTHON: Scikit-learn, Pandas, Numpy, Dask, Flask, Matplotlib, Altair, Bokeh, Dash</p>
              <p style={{lineHeight: "40px"}}>Distributed Computing: Ray, Dask</p>
              <p style={{lineHeight: "40px"}}>R: Tidyverse (ggplot2, dplyr, tidyr, tidytext), Notebooks</p>
              <p style={{lineHeight: "40px"}}>TOOLS: Conda, Docker, Jupyter Lab + Notebook, R Studio</p>
              <p style={{lineHeight: "40px"}}>JAVASCRIPT: React, D3, Node</p>
              <p style={{lineHeight: "40px"}}>PLATFORMS: AWS, Digital Ocean, Google Cloud Platform, Firebase</p>
            </div>
            <p style={{lineHeight: "40px", marginTop: "6px"}}>
              Previously I worked with C# and JAVA based technologies for more than a decade to build fintech solutions.
            </p>          
          </Panel> */}
          <Panel header="CLOUD SETUP" key="7" style={customPanelStyle}>
            <div>
              <img src={CloudStack} />
              <p style={{lineHeight: "40px"}}>You can find more details <a href="https://kb.katnoria.com/posts/2021/4/cloud-stack/">here</a></p>
            </div>
          </Panel>
        </Collapse>        
        <div style={{position: "absolute", paddingTop: "10px",bottom: "0", height: ".3rem", width: "100%"}}>
          <p style={{color: "#A9A9A9", fontSize: "0.8em"}}>Icons by svgrepo.com</p>
        </div>          
        </div>
      </div>
      </div>
      </Layout>
    )
  }
}

export default About

// export const pageQuery = graphql `
// query {
//   profilePic: file(
//     relativePath: {regex: "/ak0001.png/"}) {
//       childImageSharp {
//         fluid(maxWidth: 300, quality: 100) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     } 
// }
// `