import React from 'react';
import katex from 'katex'

const KatexEquation = (props) => {
    var eq = katex.renderToString(props.equation, {
        throwOnError: false
    });
    
    return (
        <span dangerouslySetInnerHTML={{__html: eq}} /> 
    )
  } 
  
export default KatexEquation;